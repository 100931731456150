import React, { useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { toast } from 'react-toastify';
import Logo from '../../../images/akalabet_logo.png'
import { Heading, SubHeading, Label, Input, InputContainer, Button } from '../styled.jsx'
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate } from 'react-router-dom';
import { api_url } from '../../../Utils/api-urls.jsx';
import { Oval } from 'react-loader-spinner';

export default function Login(){
    const[canSeePass, setCanSeePass]= useState(true);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            emailId: "",
            password:""
        },
        validationSchema: yup.object().shape({
            emailId: yup.string().required('Email ID is required'),
            password: yup.string().required('Password is required')
        }),   
        onSubmit: (values)=>{
            setLoader(true);
            axios.post(api_url?.login,{
                email: values?.emailId,
                password: values?.password
            })
            .then((res)=>{
                localStorage.setItem('jwt', res?.data?.user?.access_token)
                setLoader(false);
                navigate('/dashboard');
                formik.setValues({
                    emailId: "",
                    password: ""
                })
                // toast.success(res?.data?.message);
            })
            .catch((err)=>{
                console.log(err);
                toast.error(err?.response?.data?.message ? err?.response?.data?.message : "Some error occured");
                setLoader(false);
            })
        }                              
    })
    return(
        <div className='container-fluid p-0'>
            <div className='row m-0 d-flex justify-content-center align-items-center' style={{height: '80vh', background: '#F5F5F5'}}>
                <div className='col-md-6 col-xl-4 col-sm-7 col-10 p-3 card' style={{boxShadow: '0px 0px 20px #00000014', borderRadius: '20px',border: '1px solid #0000001A'}}>
                    <div className='text-center mb-4'>
                        <img src={Logo} width="115px"/>
                    </div>
                    <div className='text-center mb-3'>
                        <Heading>Admin Login</Heading>
                        {/* <SubHeading className='text-secondary'>Please fill your detail to access your account.</SubHeading> */}
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='mb-3'>
                            <Label>Email</Label>
                            <div>
                                <InputContainer>
                                <Input
                                    value={formik.values.emailId}
                                    onChange={formik.handleChange}
                                    id='emailId'
                                    name='emailId'
                                    placeholder='xyz@example.com'
                                />
                                </InputContainer>
                                { formik.touched.emailId && <p className='validation-error'>{ formik.errors.emailId && formik.errors.emailId } </p>}
                            </div>
                        </div>
                        <div className='mb-3'>
                            <Label>Password</Label>
                            <div>
                                <InputContainer>
                                    <Input
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        id='password'
                                        name='password'
                                        placeholder='Enter password'
                                        type={canSeePass ? 'password' : 'text'}
                                    />
                                    <span 
                                        onClick={()=>{
                                            setCanSeePass(!canSeePass)
                                        }}>
                                        { canSeePass ? <FiEye color='#A9A9A9'/> : <FiEyeOff color='#A9A9A9'/> }
                                    </span>
                                </InputContainer>
                            </div>
                            { formik.touched.password && <p className='validation-error'>{ formik.errors.password && formik.errors.password } </p>}
                        </div>
                        <div className='d-flex mb-4 flex-wrap align-items-center justify-content-between'>
                            <div className='d-flex align-items-center gap-1'>
                                <input type='checkbox' />
                                <Label className='mb-0'>Remember me</Label>
                            </div>
                            <Link to="/forgot-password" style={{fontSize: '14px', textDecoration: 'none', color: '#D22B2B', fontWeight: '500'}}>Forgot Password ?</Link>
                        </div>
                        <Button className='mt-3' disabled={loader} type='submit'>{loader && <Oval height={20} width={30} secondaryColor='rgb(245, 245, 245)' strokeWidth={5} color="white"/>}Log in</Button>
                    </form>
                </div>
            </div>
        </div>
    );
}