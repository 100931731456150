import React, { useState } from 'react'
import './index.css'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Logo from '../../images/akalabet_logo.png'
import CloseToggle from '../../images/sidebarOpen.png'

import DashboardActive from '../../images/dashboard_red.png'
import EventActive from '../../images/event_red.png'
import UserActive from '../../images/user_red.png'
import ReportActive from '../../images/report_red.png'
import SettingActive from '../../images/setting_red.png'
import OddsActive from '../../images/odds_red.png'
import FinanceActive from '../../images/finance_red.png'

import Finance from '../../images/finance_black.png'
import Odds from '../../images/odds_black.png'
import Dashboard from '../../images/dashboard_black.png'
import Event from '../../images/event_black.png'
import User from '../../images/user_black.png'
import Report from '../../images/report_black.png'
import Setting from '../../images/setting_black.png'
import Logout from '../../images/logout_black.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const data =[
    {
        icon: <img src={Dashboard} width="20px"/>,
        activeIcon: <img src={DashboardActive} width="20px"/>,
        name: 'Dashboard',
        link: 'dashboard',
        active: true
    },
    {
        icon: <img src={Event} width="20px"/>,
        activeIcon: <img src={EventActive} width="20px"/>,
        name: 'Events',
        link: 'event-management',
        active: false
    },
    {
        icon: <img src={User} width="20px"/>,
        activeIcon: <img src={UserActive} width="20px"/>,
        name: 'Users',
        link: 'user-management',
        active: false
    },
    {
        icon: <img src={Odds} width="13px"/>,
        activeIcon: <img src={OddsActive} width="13px"/>,
        name: 'Odds',
        link: 'odds-management',
        active: false
    },
    {
        icon: <img src={Finance} width="14px"/>,
        activeIcon: <img src={FinanceActive} width="14px"/>,
        name: 'Finance',
        link: 'finance-management',
        active: false
    },
    {
        icon: <img src={Report} width="20px"/>,
        activeIcon: <img src={ReportActive} width="20px"/>,
        name: 'Report & Analysis',
        link: 'report-management',
        active: false
    },
    {
        icon: <img src={Setting} width="20px"/>,
        activeIcon: <img src={SettingActive} width="20px"/>,
        name: 'Settings',
        link: 'settings',
        active: false
    },
];

export default function SideNavbar(){
    const[isCollapse, setIsCollapse] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    
    const handleLogout =()=>{
        localStorage.clear();
        toast.success('Logout successfully')
    }

    return(
        <Sidebar
            breakPoint='lg'
            className='sidebar-main'
            backgroundColor='white'
            collapsed={isCollapse}
            toggled={false}
        >
            <div className={isCollapse ? "sidebar-header-collapse" : "sidebar-header"}>
                { !isCollapse && <img src={Logo} width="110px"/>}
                <button onClick={()=>setIsCollapse(!isCollapse)} className='border-0 bg-transparent'>
                    <img src={CloseToggle} width="20px"/>
                </button>
                { isCollapse && <img src={Logo} width="60px"/>}
            </div>
            <Menu
            menuItemStyles={{
                button: ({ level, active }) => {
                    if (level === 0 || level === 1)
                        return {
                            color: active ? 'rgba(210, 43, 43, 1)' : 'black',
                            backgroundColor: active && 'rgba(210, 43, 43, 0.15)',
                            fontWeight: active ? 600 : 500,
                            fontSize: "13px",
                            borderRadius: '12px',
                            ":hover": {
                                backgroundColor: active ? "rgba(210, 43, 43, 0.15)" : 'transparent'
                            }
                        };
                    },
                }}
            className={isCollapse ? 'menu-collapsed' : "menu-not-collapsed"}
            >
                {
                    data?.map((menuData, index)=>{
                        return <MenuItem
                            key={index}
                            className='this-is-menuitem'
                            component={<Link className={!isCollapse &&"ps-0"} to={menuData?.link}/>}
                            active={location.pathname.includes(menuData?.link)}
                            icon={location.pathname.includes(menuData?.link) ? menuData?.activeIcon : menuData?.icon}
                        >
                            {menuData?.name}
                        </MenuItem>
                    })
                }
                <MenuItem
                    className='this-is-menuitem'
                    icon={<img src={Logout} width="17px"/>}
                    onClick={()=>handleLogout()}
                    component={<Link to="login" className={!isCollapse && "ps-0"}/>}
                >
                    Logout
                </MenuItem>
            </Menu>
        </Sidebar>
    );
}