
const environment = {
    development: {
        API_URL: process.env.REACT_APP_DEV_URL
    },
    staging: {
        API_URL: process.env.REACT_APP_STAGE_URL
    },
    production: {
        API_URL: process.env.REACT_APP_PROD_URL
    }
}

const endpoint = environment[process.env.REACT_APP_API_ENVIRONMENT]

export const api_url = {
    login: `${endpoint?.API_URL}/signin/`,
    forgot: `${endpoint?.API_URL}/forgot-password/`,
    event: `${endpoint?.API_URL}/event/`,
    getSingleEvent: (id)=>`${endpoint?.API_URL}/event/${id}/`,
    updateStatus: (id) => `${endpoint?.API_URL}/event/status/${id}/`,
    imageChanger: `${endpoint?.API_URL}/upload/image/`,
    template: `${endpoint?.API_URL}/template/`,
    singleTemplate: (id)=> `${endpoint?.API_URL}/template/${id}/`,
    users: `${endpoint?.API_URL}/users/user/`,  
    singleUsers: (id)=> `${endpoint?.API_URL}/users/user/${id}/`,
    suspendUser: (id)=> `${endpoint?.API_URL}/users/suspend/${id}/`,
    unSuspendUser: (id)=> `${endpoint?.API_URL}/users/unsuspend/${id}/`,
    // getAllOds: `${endpoint?.API_URL}/odds/odd/`,
    setOds: `${endpoint?.API_URL}/odds/set-odd/`,
    getOptionHistory: (id)=> `${endpoint?.API_URL}/odds/odd/option/${id}`
}