import React, { useLayoutEffect, useRef } from 'react'
import DisplayMessage from './displayMessage';
import NavigatorOnline from './navigatorOnline';

export default function NetworkIndicator(){
    const {show, close} = DisplayMessage();
    const isOnline = NavigatorOnline();
    
    const firstUpdate = useRef(true);

    useLayoutEffect(()=>{
        if(firstUpdate.current){
            firstUpdate.current = false;
            return;
        }

        isOnline ? show("You are back online") : close("You're currently offline");
    },[show, close, isOnline])

    return null;
}