import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { CiSearch } from "react-icons/ci";
import { Card1 } from './style';
import { IoFilter } from "react-icons/io5";
import { FiDownloadCloud } from "react-icons/fi";
import { FaCircle } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { api_url } from '../../Utils/api-urls';
import moment from 'moment/moment';
import { toast } from 'react-toastify';

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '13.5px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}

const filterData = {
    categoryData: [
        {
            value: "all",
            label: "All"
        },
        {
            value: "sports",
            label: "Sports"
        },
        {
            value: "politics",
            label: "Politics"
        },
        {
            value: "entertainment",
            label: "Entertainment"
        },
        {
            value: "weather",
            label: "Weather"
        }
    ],
    statusData: [
        {
            label: "Active",
            value: "active"
        },
        {
            label: "Closes",
            value: "closes"
        },
        {
            label: "Freeze",
            value: "freeze"
        }
    ]
}
export default function Event(){

    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const [eventData, setEventData] = useState('');

    const handleGetEventList =()=>{
        axios.get(api_url.event,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            setEventData(res?.data?.data)
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    useEffect(()=>{
        handleGetEventList()
    },[])

    const handleResume=(id)=>{
        axios.patch(api_url.updateStatus(id),{
            status: "ACTIVE"
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            console.log(res);
            toast.success("Offer resume successfully")
            handleGetEventList()
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    
    const handleFreeze=(id)=>{
        axios.patch(api_url.updateStatus(id),{
            status: 'FREEZE'
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            toast.success("Offer freeze successfully")
            console.log(res);
            handleGetEventList();
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const columns = [
        {
            name: 'Title',
            selector: (row)=> <div>
                {row?.title}
            </div>
        },
        {
            name: "Event Start",
            selector: (row)=> <div>
                {moment(row?.start_time).format("DD/MM/YYYY")}
            </div>,
        },
        {
            name: "Event End",
            selector: (row)=> <div>
                {moment(row?.end_time).format("DD/MM/YYYY")}
            </div>,
        },
        {
            name: 'Category',
            selector: (row)=> <div>
                {row?.category}
            </div>
        },
        {
            name: "Status",
            selector: (row)=> <div>
                {
                    row?.status === "FREEZE" ? <p className='mb-0 fw-bold d-flex align-items-center gap-1' style={{color: "#033278"}}><FaCircle size={5} color='#033278'/> Freeze</p> : 
                    row?.status === "ACTIVE" ? <p className='mb-0 fw-bold d-flex align-items-center gap-1' style={{color: "#780303"}}><FaCircle size={5} color='#780303'/> Live</p> :
                    row?.status === "INCOMING" ? <p className='mb-0 fw-bold d-flex align-items-center gap-1' style={{color: "#E4B528"}}><FaCircle size={5} color='#E4B528'/> Upcoming</p> :
                    <p className='mb-0 fw-bold d-flex align-items-center gap-1' style={{color: "#780303"}}><FaCircle size={5} color='#780303'/> Close</p>
                }
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div className=' d-flex align-items-center gap-2'>
                {
                    row?.status === "FREEZE" ? 
                    <button className='freeze-button' onClick={()=>handleResume(row?._id)} style={{color: "#1C7947", borderColor: "#1C7947" }}> Resume </button> :
                    <button className='freeze-button' onClick={()=>handleFreeze(row?._id)} style={{color: "#4169E1", borderColor: "#4169E1" }}> Freeze </button>
                }
                <button className='add-button' onClick={()=>navigate(`/odds-details/${row?._id}`) }>Manage Odds</button>
            </div>
        }
    ]

    const handleDownloadinEXL = ()=>{
        const data = eventData.map((data)=>{
            return {
                title: data?.title,
                description: data?.description,
                category: data?.category,
                bets: data?.total_pool,
                image: data?.image,
                startTime: data?.start_time,
                closeTime: data?.close_time,
                resolutionTime: data?.resolution_time,
                endTime: data?.end_time,
                maxBetAmount: data?.max_bet_amount,
                payoutLimit: data?.payout_limit,
                options: `${data?.options?.length} Options`,
            }
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Data.xlsx");
    }

    const handleDownloadinCSV = ()=>{
        const data = eventData.map((data)=>{
            return {
                title: data?.title,
                description: data?.description,
                category: data?.category,
                image: data?.image,
                bets: data?.total_pool,
                startTime: data?.start_time,
                closeTime: data?.close_time,
                resolutionTime: data?.resolution_time,
                endTime: data?.end_time,
                maxBetAmount: data?.max_bet_amount,
                payoutLimit: data?.payout_limit,
                options: `${data?.options?.length} Options`
            }
        })

        const worksheet = XLSX.utils.json_to_sheet(data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const searchfilter = eventData && eventData?.filter((data)=>{
        return data?.title.toLowerCase().includes(searchInput.toLowerCase()) || data?.category.toLowerCase().includes(searchInput.toLowerCase()) ||
            data?.close_time.toLowerCase().includes(searchInput.toLowerCase())
    })
    return(
        <div className='container-fluid px-0 py-3'>
            <div className='row m-0'>
                <div className='col-12'>
                    <Card1 className='px-0'>
                        <div className='table-header mb-3 px-2'>
                            <div>
                                <p className='title mb-0'>Odds</p>
                                <small className='subtitle'>Update event odds and freeze events</small>
                            </div>
                            <div className='filter-container'>
                                <div className='input-container'>
                                    <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                    <input 
                                        value={searchInput}
                                        onChange={(e)=>{
                                            setSearchInput(e.target.value)
                                        }}
                                        placeholder='search..'
                                    />
                                </div>
                                <div className="dropdown">
                                    <button className="dropdown-button" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <IoFilter size={16} color='#333333'/> Filter
                                    </button>
                                    <ul className="dropdown-menu filter-dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <p className='mb-0 filter-title'>Category</p>
                                            <p className='mb-0 cross-button'>X</p>
                                        </div>
                                        <div>
                                            {
                                                filterData?.categoryData?.map((data, index)=>{
                                                    return <div key={index} className='d-flex gap-2 ps-2 align-items-center'>
                                                        <input type='checkbox' />
                                                        <p className='mb-0 filter-menu-title'>{data?.label}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <p className='mb-0 filter-title mt-3'>Status</p>
                                        <div>
                                            {
                                                filterData?.statusData?.map((data, index)=>{
                                                    return <div key={index} className='d-flex gap-2 ps-2 align-items-center'>
                                                        <input type='radio'  name={data?.value} id={data?.value} />
                                                        <p className='mb-0 filter-menu-title'>{data?.label}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center mt-3'>
                                            <button className='add-button mt-2'> Apply Filter </button>
                                        </div>
                                    </ul>
                                </div>
                                <div className="dropdown">
                                    <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FiDownloadCloud size={16} color='#333333'/> Export
                                    </button>
                                    <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li onClick={handleDownloadinCSV} className="dropdown-item">CSV <LuDownload/> </li>
                                        <li onClick={handleDownloadinEXL} className="dropdown-item">EXCEL <LuDownload/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={searchfilter}
                            paginationPerPage={10}
                            pagination
                            customStyles={customStyles}
                        />
                    </Card1>
                </div>
            </div>
        </div>
    );
}