import React, { useEffect, useRef, useState } from 'react'
import { FaChevronLeft } from "react-icons/fa6";
import UploadImage from '../../images/uploadImg.png'
import { Card2, Heading, Subhead, Label, AddButton, CreateButton, PreviewButton, OptionInputContainer, Input, Input1, Textarea, Select, InputContainer, ContainerButton, OptionCard, OptionLabel, CreateEventButton } from './style.jsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { api_url } from '../../Utils/api-urls.jsx';
import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';
import { updateForm, createEvent, addOption, removeOption, updateOption, resetForm, setForm } from '../../Component/slices/eventSlice.jsx';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import EndTime from '../../images/endTime.png'
import StartTime from '../../images/startTime.png';
import CloseTime from '../../images/cancelTime.png';
import ResolutionTime from '../../images/resolutionTime.png';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
let style ={
    color: "#A9A9A9",
    fontWeight: '400',
    fontSize: '14px'
};

export default function CreateEvent(){
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [loader, setLoader] = useState(false);
    const {id} = params;
    const dispatch = useDispatch();
    const form = useSelector((state)=>state.event.form);

    const handleInputChange = (event)=>{
        const {name, value} = event.target;
        dispatch(updateForm({field: name, value}))
    }

    const handleInputChange1 = (name, date)=>{
        console.log(date, 'this is date')
        const value = new Date(date).toISOString();
        dispatch(updateForm({field: name, value}))
    }


    const handleCreate= async()=>{

        const formdata = {
            title: form.title,
            description: form.description,
            category: form.category,
            image: form.image,
            start_time: form.startTime,
            close_time: form.closeTime,
            resolution_time: form.resolutionTime,
            end_time: form.endTime,
            max_bet_amount: Number(form.maxBetAmount),
            payout_limit: Number(form.payoutLimit),
            eventOptions: form.options.map((data)=>{
                return { 
                    title: data?.title,
                    image: data?.image,
                    initialOdds: Number(data?.initialOdds),
                    positive_text: data?.option1,
                    negative_text: data?.option2
                }
            })
        }
        setLoader(true);
        try{
            let response = await dispatch(createEvent(formdata));
            navigate('/event-management')
            setLoader(false);
        } catch(error){
            console.loh(error)
            setLoader(false);
        }
    }

    const handleEditEvent=()=>{

        const formdata = {
            title: form.title,
            description: form.description,
            category: form.category,
            image: form.image,
            start_time: form.startTime,
            close_time: form.closeTime,
            resolution_time: form.resolutionTime,
            end_time: form.endTime,
            max_bet_amount: Number(form.maxBetAmount),
            payout_limit: Number(form.payoutLimit)
        }

        axios.patch(api_url.getSingleEvent(id),formdata, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            // console.log(res);
            dispatch(resetForm())
            navigate('/event-management')

            toast.success(res?.data?.message ? res?.data?.message : "Event Update Successfully")
        })
        .catch((error)=>{
            // console.log(error);
        })
    }

    const handleSubmit = (e)=>{
        e.preventDefault();

        if(location.pathname.includes("edit-event")){
            handleEditEvent();
        } else {
            handleCreate()
        }
    }

    const fileInputRef1 = useRef([]);

    const handleFileChange1 = (e, index) => {
        const formdata = new FormData();
        formdata.append("image", e?.target?.files[0]);

        axios.post(api_url?.imageChanger, formdata, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                "Content-Type": "multipart/form-data",
            }
        })
        .then((res)=>{
            const value = res?.data?.data;
            dispatch(updateOption({ index: index, field: "image", value }));
        })
        .catch((err)=>{
            console.log(err);
        })
    };
    const handleButtonClick1 = (index) => {
        fileInputRef1.current[index]?.click();
    };

    const fileInputRef = useRef(null);
    const handleFileChange = (event) => {

        const formdata = new FormData();
        formdata.append("image", event?.target?.files[0]);

        axios.post(api_url?.imageChanger, formdata, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                "Content-Type": "multipart/form-data",
            }
        })
        .then((res)=>{
            const value = res.data.data
            // formik.setValues({
            //     ...form,
            //     'image': res?.data?.data
            // })
            dispatch(updateForm({field: "image", value}))

        })
        .catch((err)=>{
            console.log(err);
        })
    };
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleAdd = ()=>{
        dispatch(addOption());
    }

    const handleRemove = (e, index)=>{
        e.preventDefault();
        dispatch(removeOption(index));
    }
    const handleOptionChange = (e, index)=>{
        const {name, value} = e.target;
        dispatch(updateOption({ index, field: name, value }));

        // let newOptions = [...form.options];

        // newOptions[index][name] = value;

        // if (name === 'initialOdds') {
        //     const totalOdds = newOptions.reduce((sum, option, idx) => idx !== index ? sum + parseFloat(option.initialOdds || 0) : sum, 0);
        //     const newOdds = parseFloat(value) || 0;
    
        //     if (newOdds + totalOdds > 100) {
        //         newOptions[index].initialOdds = 100 - totalOdds;
        //     } else {
        //         newOptions[index].initialOdds = newOdds;
        //     }
        // }

        // setForm({ ...form, options: newOptions });
    }

    return(
        <div className='p-3'>
            <Card2>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex gap-3'>
                        <p className='mb-0 mt-1' 
                            onClick={()=>{ 
                                navigate(-1);
                                dispatch(resetForm())
                                // location.pathname.includes('create-event') && dispatch(resetForm())
                            }}
                        >
                            <FaChevronLeft color="#D22B2B" size={17} />
                        </p>
                        {

                            location.pathname.includes("edit-template") ?  
                            <div>
                                <Heading className='mb-1'>Edit Template</Heading>
                                <Subhead>Edit template details and create new event</Subhead>
                            </div> : location.pathname.includes("edit-event") ?
                            <div>
                                <Heading className='mb-1'>Edit Event</Heading>
                                <Subhead>Edit event details and update the event</Subhead>
                            </div> : <div>
                                <Heading className='mb-1'>Create New Event</Heading>
                                <Subhead>Fill in event details and create new event</Subhead>
                            </div>
                        }   
                    </div>
                    {
                        location.pathname?.includes('create-event') && 
                        <CreateEventButton onClick={()=>navigate('/event-template')}> Choose from templates </CreateEventButton>
                    }
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='mt-3'>
                        <Label>Event Title</Label>
                        <Input
                            name="title"
                            id="title"
                            value={form?.title}
                            onChange={handleInputChange}
                            placeholder='Enter event title'
                        />
                    </div>
                    <div className='mt-3'>
                        <Label>Description <span style={{color: "#A9A9A9"}}>(optional)</span></Label>
                        <Textarea
                            name="description"
                            id="description"
                            placeholder='Enter event description'
                            value={form?.description}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='row m-0 mt-3'>
                        <div className='col-md-6 ps-0'>
                            <Label>Category</Label>
                            <Select 
                                onChange={handleInputChange} 
                                value={form.category}
                                name='category'
                                id='category'
                            >
                                <option>Select Category</option>
                                <option value="SPORT">Sports</option>
                                <option value="POLITIC">Politics</option>
                                <option value="ELECTION">Elections</option>
                                <option value="ENTERTAINMENT">Entertainment</option>
                                <option value="WEATHER">Weather</option>
                            </Select>
                        </div>
                        <div className='col-md-6 pe-0'>
                            <Label>Image</Label>
                            <InputContainer>
                                <input              
                                    placeholder='Upload Image for Event'
                                    className='border-0 bg-transparent'
                                    disabled
                                    value={form.image}
                                />
                                <Input1
                                    className='d-none'
                                    type='file'
                                    name='image'
                                    id='image'
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                               <ContainerButton type='button' onClick={handleButtonClick} className="custom-button">Browse Image <img src={UploadImage}/> </ContainerButton>
                            </InputContainer>
                        </div>
                    </div>
                    <div className='row m-0 mt-3'>
                        <div className='col-md-6 ps-0'>
                            <Label htmlFor='maxBetAmount'>Maximum Bet Amount</Label>
                            <Input
                                placeholder='Enter maximum bet amount'
                                value={form.maxBetAmount}
                                type='number'
                                onChange={handleInputChange}
                                name='maxBetAmount'
                                id='maxBetAmount'
                                />
                        </div>
                        <div className='col-md-6 pe-0'>
                            <Label htmlFor='payoutLimit'>Payout Limit (%)</Label>
                            <Input
                                placeholder='Enter payout limit'
                                type='number'
                                value={form.payoutLimit}
                                onChange={handleInputChange}
                                name='payoutLimit'
                                id='payoutLimit'
                            />
                        </div>
                    </div>
                    <div className='row m-0 mt-3'>
                        <div className='col-md-6 ps-0'>
                            <Label>Event Start Time</Label>
                            <div className="custom-date-picker">
                                <DatePicker
                                    selected={form.startTime ? new Date(form.startTime) : ''}
                                    onChange={(date) => handleInputChange1('startTime', date)}
                                    showTimeSelect
                                    dateFormat="Pp"
                                    placeholderText="Choose Time"
                                    customInput={
                                        <InputContainer>
                                            <input
                                                className='border-0 bg-transparent'
                                                disabled
                                                style={style}
                                                placeholder="Choose Time"
                                                value={form.startTime ? new Date(form.startTime).toLocaleString() : ''}
                                            />
                                            <ContainerButton type='button' className='p-1'><img src={StartTime}/></ContainerButton>           
                                        </InputContainer>
                                    }
                                />
                            </div>
                        </div>
                        <div className='col-md-6 pe-0'>
                            <Label>Event End Time</Label>      
                            <div className="custom-date-picker">
                                <DatePicker
                                    // selected={new Date(form.endTime)}
                                    selected={form.endTime ? new Date(form.endTime) : ''}
                                    onChange={(date) => handleInputChange1('endTime', date)}
                                    showTimeSelect
                                    dateFormat="Pp"
                                    placeholderText="Choose Time"
                                    customInput={
                                        <InputContainer>
                                            <input
                                                className='border-0 bg-transparent'
                                                disabled
                                                style={style}
                                                placeholder="Choose Time"
                                                value={form.endTime  ? new Date(form.endTime).toLocaleString() : ''}
                                            />
                                            <ContainerButton type="button" className='p-1'><img src={EndTime}/></ContainerButton>           
                                        </InputContainer>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row m-0 mb-4 mt-3'>
                        <div className='col-md-6 ps-0'>
                            <Label>Betting Close Time</Label>
                            <div className="custom-date-picker">
                                <DatePicker
                                    selected={form.closeTime ? new Date(form.closeTime) : ''}
                                    // selected={new Date(form.closeTime)}
                                    onChange={(date) => handleInputChange1('closeTime', date)}
                                    showTimeSelect
                                    dateFormat="Pp"
                                    minDate={new Date(form.startTime)}
                                    placeholderText="Choose Time"
                                    customInput={
                                        <InputContainer>
                                            <input
                                                className='border-0 bg-transparent'
                                                disabled
                                                style={style}
                                                placeholder="Choose Time"
                                                value={form.closeTime ? new Date(form.closeTime).toLocaleString() : ''}
                                            />
                                            <ContainerButton type='button' className='p-1'><img src={CloseTime}/></ContainerButton>           
                                        </InputContainer>
                                    }
                                />
                            </div>
                        </div>
                        <div className='col-md-6 pe-0'>
                            <Label>Event Resolution Time</Label>
                            <div className="custom-date-picker">
                                <DatePicker
                                    selected={form.resolutionTime ? new Date(form.resolutionTime) : ""}
                                    // selected={new Date(form.resolutionTime)}
                                    onChange={(date) => handleInputChange1('resolutionTime', date)}
                                    showTimeSelect
                                    minDate={new Date(form.closeTime)}
                                    dateFormat="Pp"
                                    placeholderText="Choose Time"
                                    customInput={
                                        <InputContainer>
                                            <input
                                                className='border-0 bg-transparent'
                                                disabled
                                                style={style}
                                                placeholder="Choose Time"
                                                value={form.resolutionTime ? new Date(form.resolutionTime).toLocaleString() : ""}
                                            />
                                            <ContainerButton type='button' className='p-1'><img src={ResolutionTime}/></ContainerButton>           
                                        </InputContainer>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    {
                        form?.options?.map((data, index)=>{         
                            return  <OptionCard key={index}>
                                <div className='row m-0 align-items-end' >
                                    <div className='col-md-6 ps-0'>
                                        <OptionLabel>Option Name</OptionLabel>
                                        <Input
                                            name="title"
                                            id='title'
                                            value={data?.title}
                                            onChange={(e)=>handleOptionChange(e, index)}
                                            placeholder='Enter betting option name'
                                        />
                                    </div>
                                    <div className='col-md-6 pe-0'>
                                        <span className=' d-flex justify-content-between align-items-center'>
                                            <OptionLabel htmlFor='image'>Option Image</OptionLabel>
                                            {
                                                form?.options?.length > 2 &&  <button className='bg-transparent border-0' onClick={(e)=>handleRemove(e, index)}><MdDelete/></button>
                                            }
                                        </span>
                                        <OptionInputContainer>
                                            <input
                                                placeholder='OptionImage.JPG'
                                                className='border-0 bg-transparent'
                                                style={{...style, width: '100%'}}
                                                disabled
                                                name='image'
                                                id='image'
                                                value={data?.image}
                                            />
                                            <Input1
                                                className='d-none'
                                                type='file'
                                                name='image'
                                                // id='image'
                                                id={`image-${index}`}
                                                ref={(el) => fileInputRef1.current[index] = el} 
                                                onChange={(e)=>handleFileChange1(e, index)}
                                            />
                                            <ContainerButton type='button' onClick={()=>handleButtonClick1(index)} className="custom-button">Browse <img src={UploadImage}/> </ContainerButton>
                                        </OptionInputContainer>
                                    </div>  
                                </div>
                                <div className='row m-0 align-items-end mt-3    ' >
                                    
                                    <div className='col-md-4 ps-0'>
                                        <OptionLabel htmlFor='initialOdds'>Initial Odd (%)</OptionLabel>
                                        <Input
                                            name='initialOdds'
                                            id='initialOdds'
                                            type='number'
                                            value={data?.initialOdds}
                                            onChange={(e)=>{handleOptionChange(e, index)}}
                                            placeholder='Enter initial odd for this option'
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <OptionLabel htmlFor='option1'>Action 1</OptionLabel>
                                        <Input
                                            name="option1"
                                            id='option1'
                                            value={data?.option1}
                                            onChange={(e)=>handleOptionChange(e, index)}
                                            placeholder='Win'
                                        />
                                    </div>
                                    <div className='col-md-4 pe-0'>
                                        <OptionLabel htmlFor='option2'>Action 2</OptionLabel>
                                        <Input
                                            name='option2'
                                            id='option2'
                                            value={data?.option2}
                                            onChange={(e)=>{handleOptionChange(e, index)}}
                                            placeholder='Lose'
                                        />
                                    </div>  
                                </div>
                            </OptionCard>
                        })
                    }

                    <AddButton type='button' onClick={handleAdd}>
                        <FaPlus color={ form?.options?.length === 5 ? '#78727285' : '#787272'}/> Add More Options                                 
                    </AddButton>

                    <div className='row m-0 mt-4 d-flex justify-content-around align-items-center'>
                        {!location.pathname.includes("edit-event") && <div className='col-md-6 col-10'>
                            <PreviewButton onClick={()=>navigate('/event-preview')} type='button' >Event Preview</PreviewButton>
                        </div>}
                        <div className='col-md-6 col-10'>
                            <CreateButton type='submit' disabled={loader} >{loader ? <Oval height={22} strokeWidth={8} width={22} secondaryColor='grey' color="white" /> : `${location.pathname.includes("edit-event") ? "Edit" : "Create"} Event`}</CreateButton>
                        </div>
                    </div>
                </form>
            </Card2>
        </div>
    );
}