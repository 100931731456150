import React, { useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik';
import * as yup from 'yup'
import Logo from '../../../images/akalabet_logo.png'
import { Heading, SubHeading, Label, Input, InputContainer, Button } from '../styled.jsx'
import { Link, useNavigate } from 'react-router-dom';
import { api_url } from '../../../Utils/api-urls.jsx';
import './index.css'
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';

export default function Forgot(){

    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const formik = useFormik({
        initialValues: {
            emailId: "",
        },
        validationSchema: yup.object().shape({
            emailId: yup.string().required('Email ID is required'),
        }),
        onSubmit: (values) => {
            setLoader(true);
            axios.post(api_url.forgot,{
                email: values?.emailId
            })
            .then((res)=>{
                console.log(res);
                toast.success(res?.data?.message[0]);
                navigate('/login')
                setLoader(false);
            })
            .catch((error)=>{
                console.log(error);
                setLoader(false);
                toast.error(error?.response?.data?.message ? error?.response?.data?.message : "Some error occured");
            })
        }
    })

    return(
        <div className='container-fluid p-0'>
            <div className='row m-0 d-flex justify-content-center align-items-center' style={{height: '80vh', background: '#F5F5F5'}}>
                <div className='col-md-6 col-xl-4 col-sm-7 col-10 p-3 card' style={{boxShadow: '0px 0px 20px #00000014', borderRadius: '20px',border: '1px solid #0000001A'}}>
                    <div className='text-center mb-4'>
                        <img src={Logo} width="115px"/>
                    </div>
                    <div className='text-center mb-3'>
                        <Heading>Forgot Password</Heading>
                        <SubHeading className='text-secondary'>Please fill your detail to forgot your password.</SubHeading>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='mb-4'>
                            <Label>Email</Label>
                            <div>
                                <InputContainer>
                                <Input
                                    value={formik.values.emailId}
                                    onChange={formik.handleChange}
                                    id='emailId'
                                    name='emailId'
                                    placeholder='xyz@example.com'
                                />
                                </InputContainer>
                                {formik.touched.emailId && <p className='validation-error'>{ formik.errors.emailId && formik.errors.emailId } </p>}
                            </div>
                        </div>
                        <Button disabled={loader} className='mb-3' type='submit'>{loader && <Oval height={20} width={30} secondaryColor='rgb(245, 245, 245)' strokeWidth={5} color="white"/>}Send Link</Button>
                        <div className='mt-2 text-center'>
                            <Link to='/login' style={{fontSize: '14px', textDecoration: 'none', color: '#D22B2B', fontWeight: '500'}}>Back to login</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}