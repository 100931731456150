import { configureStore } from "@reduxjs/toolkit";
import eventReducer from './Component/slices/eventSlice'
import UserReducer from './Component/slices/userSlice'

const store = configureStore({
    reducer: {
        event: eventReducer,
        users: UserReducer
    }
})
export default store;