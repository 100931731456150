import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import TotalEvent from '../../images/totalEvent.png'
import TotalBet from '../../images/totalBet.png'
import { CiSearch } from "react-icons/ci";
import { Card, Card1, Active } from './style';
import { IoFilter } from "react-icons/io5";
import { FiDownloadCloud } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { FaCircle } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getEventList, setForm } from '../../Component/slices/eventSlice';
import * as XLSX from 'xlsx';
import ViewImg from '../../images/eye-view.png'
import EditImg from '../../images/pencil-edit.png'
import DeleteImg from '../../images/bin-delete.png'
import axios from 'axios';
import { api_url } from '../../Utils/api-urls';
import { toast } from 'react-toastify';

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '13.5px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}

const filterData = {
    categoryData: [
        {
            value: "all",
            label: "All"
        },
        {
            value: "sports",
            label: "Sports"
        },
        {
            value: "politics",
            label: "Politics"
        },
        {
            value: "entertainment",
            label: "Entertainment"
        },
        {
            value: "weather",
            label: "Weather"
        }
    ],
    statusData: [
        {
            label: "Active",
            value: "active"
        },
        {
            label: "Closes",
            value: "closes"
        },
        {
            label: "Freeze",
            value: "freeze"
        }
    ]
}
export default function Event(){

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState('');
    const eventData = useSelector((state)=> state?.event?.items)

    useEffect(()=>{
        dispatch(getEventList())
    },[])

    const columns = [
        {
            name: 'Title',
            selector: (row)=> <div>
                {row?.title}
            </div>
        },
        {
            name: 'Category',
            selector: (row)=> <div>
                {row?.category}
            </div>
        },
        {
            name: "No. of Bets",
            selector: (row)=> <div>
                {row?.total_pool ? row?.total_pool : '0'} Bets
            </div>
        },
        {
            name: "Close Date",
            selector: (row)=> <div>
                {new Date(row?.close_time).toDateString()}
            </div>,
        },
        {
            name: "Status",
            selector: (row)=> <div>
                {
                    row?.status === "FREEZE" ? <Active color="#033278" background= "#ECF0FD"><FaCircle size={7} color='#033278'/>Freeze</Active> : 
                    row?.status === "ACTIVE" ? <Active color="#037847" background= "#ECFDF3"><FaCircle size={7} color='#037847'/>Active</Active> :
                    row?.status === "INCOMING" ? <Active color="#E4B528" background= "#E4B5281A"><FaCircle size={7} color='#E4B528'/>Upcoming</Active> :
                    <Active color="#780303" background= "#FDECEC"><FaCircle size={7} color='#780303'/>Close</Active>
                }
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div className=' d-flex gap-3'>
                <img src={ViewImg} onClick={()=>navigate(`/event-details/${row?._id}`) }/>
                <img src={EditImg} onClick={()=>handleClickEdit(row)}/>
                <img src={DeleteImg} onClick={()=> handleDelete(row?._id)}/>
            </div>
        }
    ]

    const handleClickEdit=(row)=>{
        const data = {
            title: row?.title,
            description: row?.description,
            category: row?.category,
            image: row?.image,
            startTime: row?.start_time,
            closeTime: row?.close_time,
            resolutionTime: row?.resolution_time,
            endTime: row?.end_time,
            maxBetAmount: row?.max_bet_amount,
            payoutLimit: row?.payout_limit,
            status: row?.status,
            options : row?.options?.map((data)=>{
                return {
                    title: data.title,
                    image: data?.image,
                    initialOdds: data?.initialOdds,
                    option1: data?.positive_text, 
                    option2: data?.negative_text
                }
            })
        }
        dispatch(setForm(data));
        navigate(`/edit-event/${row?._id}`)
    }

    const handleDownloadinEXL = ()=>{
        const data = eventData.map((data)=>{
            return {
                title: data?.title,
                description: data?.description,
                category: data?.category,
                bets: data?.total_pool,
                image: data?.image,
                startTime: data?.start_time,
                closeTime: data?.close_time,
                resolutionTime: data?.resolution_time,
                endTime: data?.end_time,
                maxBetAmount: data?.max_bet_amount,
                payoutLimit: data?.payout_limit,
                options: `${data?.options?.length} Options`,
            }
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Data.xlsx");
    }

    const handleDownloadinCSV = ()=>{
        const data = eventData.map((data)=>{
            return {
                title: data?.title,
                description: data?.description,
                category: data?.category,
                image: data?.image,
                bets: data?.total_pool,
                startTime: data?.start_time,
                closeTime: data?.close_time,
                resolutionTime: data?.resolution_time,
                endTime: data?.end_time,
                maxBetAmount: data?.max_bet_amount,
                payoutLimit: data?.payout_limit,
                options: `${data?.options?.length} Options`
            }
        })

        const worksheet = XLSX.utils.json_to_sheet(data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleDelete = (id)=>{
        axios.delete(api_url.getSingleEvent(id),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            toast.success("Your event is delete successfully");
            dispatch(getEventList())
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    
    const data = [
        {
            image: <img src={TotalEvent} width="60px"/>,
            title: "Events",
            total: eventData?.length ? eventData?.length : 0,
            active: "0",
        },
        {
            image: <img src={TotalBet} width="60px"/>,
            title: "Bets",
            total: "0",
            active: "0",
        },
    ];

    const searchfilter = eventData && eventData?.filter((data)=>{
        return data?.title.toLowerCase().includes(searchInput.toLowerCase()) || data?.category.toLowerCase().includes(searchInput.toLowerCase()) ||
            data?.close_time.toLowerCase().includes(searchInput.toLowerCase())
    })
    return(
        <div className='container-fluid px-0 py-3'>
            <div className='row m-0 d-flex flex-wrap justify-content-center justify-content-md-between'>
                { 
                    data?.map((data, index)=>{
                        return <div key={index} className="col-md-6 mb-3 col-10">
                            <Card>  
                                <div>
                                    <p className='mb-0 title'>Total {data?.title}</p>
                                    <p className='total mb-2'>{data?.total}</p>
                                    <p className='mb-0 active-line'><span className='primary'>{data?.active}</span> Active {data?.title}</p>
                                </div>
                                <div>{data?.image}</div>
                            </Card>
                        </div>
                    })
                }
            </div>
            <div className='row m-0'>
                <div className='col-12'>
                    <Card1 className='px-0'>
                        <div className='table-header mb-3 px-2'>
                            <div>
                                <p className='title mb-0'>Events</p>
                                <small className='subtitle'>Manage and track all events</small>
                            </div>
                            <div className='filter-container'>
                                <div className='input-container'>
                                    <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                    <input 
                                        value={searchInput}
                                        onChange={(e)=>{
                                            setSearchInput(e.target.value)
                                        }}
                                        placeholder='search..'
                                    />
                                </div>
                                <div className="dropdown">
                                    <button className="dropdown-button" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <IoFilter size={16} color='#333333'/> Filter
                                    </button>
                                    <ul className="dropdown-menu filter-dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <p className='mb-0 filter-title'>Category</p>
                                            <p className='mb-0 cross-button'>X</p>
                                        </div>
                                        <div>
                                            {
                                                filterData?.categoryData?.map((data, index)=>{
                                                    return <div key={index} className='d-flex gap-2 ps-2 align-items-center'>
                                                        <input type='checkbox' />
                                                        <p className='mb-0 filter-menu-title'>{data?.label}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <p className='mb-0 filter-title mt-3'>Status</p>
                                        <div>
                                            {
                                                filterData?.statusData?.map((data, index)=>{
                                                    return <div key={index} className='d-flex gap-2 ps-2 align-items-center'>
                                                        <input type='radio'  name={data?.value} id={data?.value} />
                                                        <p className='mb-0 filter-menu-title'>{data?.label}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center mt-3'>
                                            <button className='add-button mt-2'> Apply Filter </button>
                                        </div>
                                    </ul>
                                </div>
                                <div className="dropdown">
                                    <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FiDownloadCloud size={16} color='#333333'/> Export
                                    </button>
                                    <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li onClick={handleDownloadinCSV} className="dropdown-item">CSV <LuDownload/> </li>
                                        <li onClick={handleDownloadinEXL} className="dropdown-item">EXCEL <LuDownload/></li>
                                    </ul>
                                </div>
                                <button className='add-button' onClick={()=>{navigate("/create-event")}}>
                                    <FaPlus color='white' strokeWidth={2}/>
                                    Create New Event
                                </button>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={searchfilter}
                            paginationPerPage={10}
                            pagination
                            customStyles={customStyles}
                        />
                    </Card1>
                </div>
            </div>
        </div>
    );
}