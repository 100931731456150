import React, { useRef, useState } from 'react'
import { Card } from './style';
import AdsImage from '../../images/adsmgmt.png'
import { AddButton, ContainerButton, Input1, InputContainer, Label, OptionInputContainer } from '../Event/style';
import UploadImage from '../../images/uploadImg.png'
import axios from 'axios';
import { api_url } from '../../Utils/api-urls';
import { FaPlus } from 'react-icons/fa6';
let style ={
    color: "#A9A9A9",
    fontWeight: '400',
    fontSize: '14px'
};
export default function Setting(){

    const fileInputRef1 = useRef([]);
    
    const handleFileChange1 = (e, index) => {
        const formdata = new FormData();
        formdata.append("image", e?.target?.files[0]);

        axios.post(api_url?.imageChanger, formdata, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                "Content-Type": "multipart/form-data",
            }
        })
        .then((res)=>{
            const value = res?.data?.data;
            // dispatch(updateOption({ index: index, field: "image", value }));
        })
        .catch((err)=>{
            console.log(err);
        })
    };

    const handleButtonClick1 = (index) => {
        fileInputRef1.current[index]?.click();
    };

    const [imagesList, setImageList] = useState([{ image: "" }, { image: "" }]);

    const handleAdd =()=>{

    }
    return(
        <p className='p-3'>
            <Card className='row m-0'>
                <p className='heading'>Settings</p>
                <div className='col-md-3' style={{borderRight: "2px solid #F5F5F5"}}>
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link active" id="nav-bet-tab" data-bs-toggle="tab" data-bs-target="#nav-bet" type="button" role="tab" aria-controls="nav-bet" aria-selected="true"><img src={AdsImage} width="20px"/>Ads Mangement</button>
                            <button class="nav-link" id="nav-transaction-tab" data-bs-toggle="tab" data-bs-target="#nav-transaction" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Transaction</button>
                        </div>
                    </nav>
                </div>
                <div className='col-md-9'>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-bet" role="tabpanel" aria-labelledby="nav-bet-tab">
                            <div className='header'>
                                <p className='title'>Ads</p>       
                                <p className='sub-title'>Update Ads Banner for users</p>
                            </div>
                            { 
                                imagesList.map((data, index)=>{
                                    return <div className='mt-2'>
                                        <Label>Banner Image 1</Label>
                                        <div className='d-flex gap-2'>
                                            <OptionInputContainer className='p-1'>
                                                <input
                                                    placeholder='OptionImage.JPG'
                                                    className='border-0 bg-transparent'
                                                    style={{...style}}
                                                    disabled
                                                    name='image'
                                                    id='image'
                                                    value={data?.image}
                                                />
                                                <Input1
                                                    className='d-none'
                                                    type='file'
                                                    name='image'
                                                    id={`image-${index}`}
                                                    ref={(el) => fileInputRef1.current[index] = el} 
                                                    onChange={(e)=>handleFileChange1(e, index)}
                                                />
                                                <ContainerButton style={{fontSize: '15px'}} type='button' onClick={()=>handleButtonClick1(index)} className="custom-button">Browse Image <img src={UploadImage}/> </ContainerButton>
                                            </OptionInputContainer>
                                            <button className='button-preview'>preview</button>
                                            <button className='button-delete'>delete</button>
                                        </div>
                                    </div>
                                }) 
                            }  
                            <AddButton type='button' onClick={handleAdd}>
                                <FaPlus color='#787272'/> Add More Banners                                 
                            </AddButton>
                        </div>
                    </div>
                </div>
            </Card>
        </p>
    );
}