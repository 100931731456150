import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { api_url } from '../../Utils/api-urls';
import { Card2, Nav, Bets, Profile } from './styled';
import { FaChevronLeft } from 'react-icons/fa';
import { MdBlock } from "react-icons/md";
import { toast } from 'react-toastify';
import Sample from '../../images/headerImage.png'
import TotalBetImg from '../../images/total_bets.png';
import TotalWonImg from '../../images/total_won.png';
import TotalLoseImg from '../../images/total_loose.png';
import { CiSearch } from 'react-icons/ci';
import { FiDownloadCloud } from 'react-icons/fi';
import { LuDownload } from 'react-icons/lu';
import Unsuspend from '../../images/unsuspend_icon.png'
import { Active } from '../Event/style';
import DataTable from 'react-data-table-component';

const betdata = [
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "won"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "lost"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "ongoing"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "won"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "lost"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "won"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "lost"
    }

]
const txnData = [
    {
        hash: "#TX67890TX67890TX67890TX67890",
        date: "12/08/2024",
        txn_type: "deposite",
        amount: "₱1,000",
        balance: "₱2,500",
        status: "complete"
    },
    {
        hash: "#TX67890TX67890TX67890TX67890",
        date: "12/08/2024",
        txn_type: "Withdrawal",
        amount: "₱1,000",
        balance: "₱2,500",
        status: "pending"
    },
    {
        hash: "#TX67890TX67890TX67890TX67890",
        date: "12/08/2024",
        txn_type: "Withdrawal",
        amount: "₱1,000",
        balance: "₱2,500",
        status: "complete"
    },
    {
        hash: "#TX67890TX67890TX67890TX67890",
        date: "12/08/2024",
        txn_type: "deposite",
        amount: "₱1,000",
        balance: "₱2,500",
        status: "complete"
    },
    {
        hash: "#TX67890TX67890TX67890TX67890",
        date: "12/08/2024",
        txn_type: "deposite",
        amount: "₱1,000",
        balance: "₱2,500",
        status: "complete"
    }
]
const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '13.5px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}
export default function SingleUser(){

    const navigate = useNavigate();
    const params = useParams();
    const {id} = params;
    const [userData, setUserData] = useState();

    const column1 = [
        {
            name: "Event Title",
            selector: (row)=><div>
                {row.title}
            </div>
        },
        {
            name: "Bet Placed",
            selector: (row)=><div>
                {row.betplace}
            </div>
        },
        {
            name: "Odds",
            selector: (row)=><div>
                {row.odds}
            </div>
        },
        {
            name: "Bet",
            selector: (row)=><div>
                {row.bet}
            </div>
        },
        {
            name: "Potential Payout",
            selector: (row)=><div>
                {row.potential}
            </div>
        },
        {
            name: "Status",
            selector: (row)=><div>
                <Active
                    color={row?.status === "won" ? "#037847" : row?.status === "lost" ? "#D22B2B" : "#4169E1"}
                    background={row?.status === "ongoing" && "#4169E11A"}
                >
                    {row?.status === "won" ? "Won" : row?.status === "lost" ? "Lost" : "Ongoing"}
                </Active>
            </div>
        },
    ]
    const column2 =[
        {
            name: "Transaction ID",
            selector: (row)=><div>
                {row?.hash}
            </div>
        },
        {
            name: "Date",
            selector: (row)=><div>
                {row?.date}
            </div>
        },
        {
            name: "Type",
            selector: (row)=><div>
                {row?.txn_type}
            </div>
        },
        {
            name: "Amount",
            selector: (row)=><div>
                {row?.amount}
            </div>
        },
        {
            name: "Balance",
            selector: (row)=><div>
                {row?.balance}
            </div>
        },
        {
            name: "Status",
            selector: (row)=><div>
                <Active color={row?.status === "complete" ? "#037847" : "#E4B528"} >
                    {row?.status}
                </Active>
            </div>
        }
    ]
    const data = [
        {
            name: "Total Bets",
            num: userData?.total_bet,
            image: <img width="55px" src={TotalBetImg} />
        },
        {
            name: "Total Won",
            num: userData?.total_earn,
            image: <img width="55px" src={TotalWonImg} />
        },
        {
            name: "Total Loss",
            num: userData?.total_lose,
            image: <img width="55px" src={TotalLoseImg} />
        }
    ];

    const handleSuspend = (id)=>{
        axios.post(api_url.suspendUser(id),{},{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            handleFetchThisUser(id);
            toast.success(res?.data?.message ? res?.data?.message : "user suspended successfully.")
        })
        .catch((error)=>{
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : "some error occured");
            console.log(error);
        })
    }
    const handleUnSuspend = (id)=>{
        axios.post(api_url.unSuspendUser(id),{},{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            toast.success(res?.data?.message ? res?.data?.message : 'User is successfully un-suspended!!')
            handleFetchThisUser(id);
        })
        .catch((error)=>{
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : "some error occured")
        })
    }

    const handleFetchThisUser=(id)=>{
        axios.get(api_url.singleUsers(id),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            console.log(res);
            setUserData(res?.data?.data);
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        handleFetchThisUser(id)
    },[])

    return(
        <div className='p-3'>
            <Card2>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center gap-3'>
                        <FaChevronLeft color="#D22B2B" size={17}
                            onClick={()=>{
                                navigate(-1);
                            }}
                        />
                        <img src={userData?.image ? userData?.image : Sample} width="80px"/>
                        <div>
                            <p className='heading mb-1'>{userData?.username}</p>
                            <p className='sub-head mb-0'>Email: {userData?.email}</p>
                            <p className='sub-head mb-0'>Joined On: {new Date(userData?.createdAt).toLocaleDateString()}</p>
                        </div>
                    </div>
                    {
                        userData?.isSuspended ? 
                        <button className='green-button' onClick={()=>handleUnSuspend(id)}><img src={Unsuspend} width="17px"/> Un-suspend</button> :
                        <button className='add-button' onClick={()=>handleSuspend(id)}><MdBlock size={18}/> Suspend</button>
                    }
                </div>
                <div className='d-flex gap-3 mt-4'>
                    {
                        data?.map((data)=>{
                            return <div className='total-card'>
                                <div>
                                    <p className='mb-0 sub-head'>{data?.name}</p>
                                    <p className='mb-0 heading'>{data?.num}</p>
                                </div>
                                {data?.image}
                            </div>
                        })
                    }
                </div>
                <Nav className='mt-4'>
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link active" id="nav-bet-tab" data-bs-toggle="tab" data-bs-target="#nav-bet" type="button" role="tab" aria-controls="nav-bet" aria-selected="true">All Bets</button>
                            <button class="nav-link" id="nav-transaction-tab" data-bs-toggle="tab" data-bs-target="#nav-transaction" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Transaction</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-bet" role="tabpanel" aria-labelledby="nav-bet-tab">
                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                <div>
                                    <p className='nav-head mb-0'>Bets</p>
                                    <p className='nav-subhead'>Manage and track all user’s bet</p>
                                </div>
                                <div className='d-flex gap-2'>
                                    <div className='input-container'>
                                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                        <input 
                                            // value={searchInput}
                                            // onChange={(e)=>{
                                            //     setSearchInput(e.target.value)
                                            // }}
                                            placeholder='search..'
                                        />
                                    </div>
                                    <div className="dropdown">
                                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FiDownloadCloud size={16} color='#333333'/> Export
                                        </button>
                                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                            <li className="dropdown-item">CSV <LuDownload/> </li>
                                            <li className="dropdown-item">EXCEL <LuDownload/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Bets>
                                <DataTable
                                    columns={column1}
                                    data={betdata}
                                    customStyles={customStyles}
                                />
                            </Bets>
                        </div>
                        <div class="tab-pane fade" id="nav-transaction" role="tabpanel" aria-labelledby="nav-transaction-tab">
                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                <div>
                                    <p className='nav-head mb-0'>Transaction</p>
                                    <p className='nav-subhead'>Manage and track all user’s transaction</p>
                                </div>
                                <div className='d-flex gap-2'>
                                    <div className='input-container'>
                                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                        <input
                                            placeholder='search..'
                                        />
                                    </div>
                                    <div className="dropdown">
                                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FiDownloadCloud size={16} color='#333333'/> Export
                                        </button>
                                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                            <li className="dropdown-item">CSV <LuDownload/> </li>
                                            <li className="dropdown-item">EXCEL <LuDownload/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Profile>
                                <DataTable
                                    columns={column2}
                                    data={txnData}
                                    customStyles={customStyles}
                                />
                            </Profile>
                        </div>
                    </div>
                </Nav>
            </Card2>
        </div>
    );
}