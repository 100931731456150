import React from 'react'
import { Active, Card2, Heading, Subhead, ViewButton } from './style';
import { FaChevronLeft, FaCircle } from 'react-icons/fa6';
import { CiSearch } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { VscEye } from 'react-icons/vsc';

const customStyles = {
    headRow: {
      style: {
        fontSize: '14px',
        fontWeight: '500',
        color: "rgba(51, 51, 51, 0.5)",
        // border: "1px solid red",
        border: "1px solid rgba(234, 236, 240, 1)",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: 'rgba(102, 102, 102, 1)',
        fontSize: '14px',
        fontWeight: "400",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
        padding: '0px 0px 0px 10px',
      },
    }
}

export default function ViewOptionUser(){
    const navigate = useNavigate();

    const columns = [
        {
            name: 'User Name',
            selector: (row)=> <div>
                {row?.name}
            </div>
        },
        {
            name: 'Bet Date',
            selector: (row)=> <div>
                {row?.date}
            </div>
        },
        {
            name: "Bet Amount",
            selector: (row)=> <div>
                {row?.amount}
            </div>
        },
        {
            name: "Yes/No",
            selector: (row)=> <div>
                {row?.prediction}
            </div>
        },
        {
            name: "Payout(If Wins)",
            selector: (row)=> <div>
                {row?.winStatus ? row?.amount : "₱ 0"}
            </div>
        },
        {
            name: "Status",
            selector: (row)=> <div>
                {    
                    row?.winStatus ? 
                    <Active color="#037847" background= "#ECFDF3"><FaCircle size={7} color='#037847'/>Won</Active> : 
                    <Active color="#780303" background= "#FDECEC"><FaCircle size={7} color='#BA1414'/>Lost</Active>
                }
            </div>
        },
        {
            name: "Action",
            selector: (row, index)=> <div>
                <ViewButton 
                    onClick={()=>{
                        navigate(`/user-management/${index+1}`)
                    }}
                > 
                    <VscEye size={16} color='white'/> View More
                </ViewButton>
            </div>
        }
    ]

    const userdata = [
        {
            name: "John Blue",
            date: "12/08/2024",
            amount: "₱ 1200",
            prediction: "Yes",
            winStatus: true
        },
        {
            name: "John",
            date: "12/08/2024",
            amount: "₱ 500",
            prediction: "No",
            winStatus: false
        },
        {
            name: "Blue",
            date: "12/08/2024",
            amount: "₱ 1250",
            prediction: "Yes",
            winStatus: true
        },
        {
            name: "green",
            date: "12/08/2024",
            amount: "₱ 2500",
            prediction: "No",
            winStatus: false
        },
        {
            name: "alen",
            date: "12/08/2024",
            amount: "₱ 100",
            prediction: "Yes",
            winStatus: true
        },
        {
            name: "John doe",
            date: "12/08/2024",
            amount: "₱ 1500",
            prediction: "No",
            winStatus: false
        },
        {
            name: "heaven",
            date: "12/08/2024",
            amount: "₱ 1200",
            prediction: "Yes",
            winStatus: true
        },
        {
            name: "John cena",
            date: "12/08/2024",
            amount: "₱ 500",
            prediction: "Yes",
            winStatus: true
        },
        {
            name: "adam",
            date: "12/08/2024",
            amount: "₱ 12,500",
            prediction: "No",
            winStatus: false
        },
    ]

    return(
        <div className='p-3'>
            <Card2>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex gap-3'>
                        <p className='mb-0 mt-1' onClick={()=>{ navigate(-1) }}>
                            <FaChevronLeft color="#D22B2B" size={17} />
                        </p>
                        <div>
                            <Heading className='mb-1'>Users</Heading>
                            <Subhead>Track all users & their bets</Subhead>
                        </div>
                    </div>   
                    <div className='input-container'>
                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                        <input 
                            placeholder='search..'
                        />
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={userdata}
                    paginationPerPage={10}
                    pagination
                    customStyles={customStyles}
                />
            </Card2>
        </div>
    );
}