import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Routers from './Component/RoutesAndLayout';
import ProgressBar from './Hooks/use-nprogress';
import NetworkIndicator from './Hooks/networkIndicator';
function App() {
  return (<>
    <ProgressBar/>
    <NetworkIndicator/>
    <Routers/>
    <ToastContainer/>
  </>);
}

export default App;