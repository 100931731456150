import React, { useCallback, useState } from "react";

export default function DisplayMessage(){
    const [message, setMessage] = useState(null);
    const [type, setType] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const show = useCallback((msg, msgType = "info", duration = 3000)=>{
        setMessage(msg);
        setType(msgType);
        setIsVisible(true);

        setTimeout(() => {
            setIsVisible(false);
        }, duration);

    },[])

    const close = useCallback(()=>{
        setIsVisible(false);
    },[])

    console.log(message)

    return { show, close, message, type, isVisible };
}
