import React, { useEffect, useState } from 'react'
import { Card2, Heading, Subhead, ViewOption, EditButton1, ImageContainer, Label, InputContainer, ContainerButton, Input1 } from '../Event/style';
import { FaChevronLeft } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import StartTime from '../../images/startTime.png'
import CloseTime from '../../images/cancelTime.png'
import ResolutionTime from '../../images/resolutionTime.png'
import EndTime from '../../images/endTime.png'
import DataTable from 'react-data-table-component';
import { api_url } from '../../Utils/api-urls';
import axios from 'axios';
import ViewImg from '../../images/eye-view.png'
import EditImg from '../../images/pencil-edit.png'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Modal } from 'react-bootstrap';
import './style.css'

const customStyles = {
    headRow: {
      style: {
        fontSize: '14px',
        fontWeight: '500',
        color: "rgba(51, 51, 51, 0.5)",
        // border: "1px solid red",
        border: "1px solid rgba(234, 236, 240, 1)",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: 'rgba(102, 102, 102, 1)',
        fontSize: '14px',
        fontWeight: "400",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        paddingRight: '0px',
        paddingLeft: '10px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
        padding: '0px 0px 0px 10px',
      },
    }
}

export default function SingleEventOds(){
    const navigate = useNavigate();
    
    const params = useParams();
    const {id} = params;

    const[singleEventData, getSingleEventData] = useState();

    const [updateOds, setUpdateOds] = useState(false);
    const handleShow=(optionId, eventId)=>{
        formik.setValues({
            ...formik.values,
            eventId: eventId,
            optionId: optionId
        })
        setUpdateOds(true);
    }
    const handleClose=()=>{
        setUpdateOds(false);
        formik.setValues({
            eventId: "",
            optionId: "",
            updateOdds: "",
            reason: ""
        })
    }

    const formik = useFormik({
        initialValues: {
            eventId: "",
            optionId: "",
            updateOdds: "",
            reason: ""
        },
        validationSchema: yup.object().shape({
            eventId: yup.string(),
            optionId: yup.string(),
            updateOdds: yup.string().required("Odd is required"),
            reason: yup.string().required("Reason is required")
        }),
        onSubmit: (values)=>{
            console.log(values);
            axios.post(api_url.setOds,{
                eventId: values.eventId,
                optionId: values.optionId,
                updateOdds: values.updateOdds,
                reason: values.reason
            },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    Accept: "application/json"
                }
            })
            .then((res)=>{
                handleClose()
                getEventDetails();
            })
            .catch((err)=>{
            })
        }
    })

    const columns = [
        {
            name: 'Options',
            selector: (row)=> <div className='d-flex gap-2 align-items-center'>
                <ImageContainer>
                    <img src={row?.image} height="30px" width="30px"/>
                </ImageContainer>
                <p className='mb-0'>{row?.title}</p>
            </div>
        },
        {
            name: "Initial Odd",
            selector: (row)=> <div>
                {row?.initialOdds}%
            </div>  
        },
        {
            name: 'Bet on Yes',
            selector: (row)=> <div>
                {row?.yesBets ? `₱ ${row?.yesBets}` : "₱ 0"}
            </div>
        },
        {
            name: "Bet on No",
            selector: (row)=> <div>
                {row?.noBets ? `₱ ${row?.noBets}` : "₱ 0"}
            </div>
        },
        {
            name: "Current Odd",
            selector: (row)=> <div>
                {row?.currentOdds}%
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div className='d-flex gap-3'>
                <img src={ViewImg} onClick={()=> getOptionsHistory(row?._id)}/>
                <img src={EditImg} onClick={()=>handleShow(row?._id, row?.eventId)}/>
            </div>
        }
    ]
    const getEventDetails = ()=>{
        axios.get(api_url.getSingleEvent(id),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            getSingleEventData(res?.data?.data);
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    useEffect(()=>{
        getEventDetails();
    },[])

    const getOptionsHistory = (id)=>{
        axios.get(api_url.getOptionHistory(id),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            console.log(res);
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    
    console.log(formik.values)
    return(
        <div className='p-3'>
            <Card2>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex gap-3'>
                        <p className='mb-0 mt-1' 
                            onClick={()=>{ 
                                navigate(-1);
                            }}
                        >
                            <FaChevronLeft color="#D22B2B" size={17} />
                        </p>
                        <div>
                            <Heading className='mb-1'>Event Detail</Heading>
                            <Subhead>Detailed Overview of Event</Subhead>
                        </div>
                    </div>
                </div>
                <div className='row m-0 mt-3'>
                    <div className='col-md-5'>
                        <img src={singleEventData?.image} width="100%" height="150px" />
                    </div>
                    <div className='col-md-7 d-flex flex-column justify-content-between'>
                        <p className='mb-0 event-title'>{singleEventData?.title}</p>
                        <div className="row m-0 d-flex justify-content-between align-items-center">
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <label className='view-labels'>Max Bet : </label>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <EditButton1 disabled> {singleEventData?.max_bet_amount} </EditButton1>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <label className='view-labels'>Category : </label>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <EditButton1 disabled> {singleEventData?.category} </EditButton1>
                            </div>  
                        </div>
                        <div className="row m-0 d-flex justify-content-between align-items-center">
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <label className='view-labels'>Payout Limit : </label>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <EditButton1 disabled> {singleEventData?.payout_limit} </EditButton1>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <label className='view-labels' style={{width: "72px"}}>Status : </label>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <EditButton1 disabled> {singleEventData?.status} </EditButton1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-0 mt-5'>
                    <div className='col-md-3'>
                        <Label>Event Start Time</Label>
                        <InputContainer className='gap-2'>
                            <ContainerButton className='p-1'> <img src={StartTime} /></ContainerButton>
                            <Input1
                                disabled={true}
                                className='bg-transparent border-0'
                                placeholder={new Date(singleEventData?.start_time).toLocaleString()}
                                />
                        </InputContainer>
                    </div>
                    <div className='col-md-3'>
                        <Label>Event End Time</Label>
                        <InputContainer className='gap-2'>
                            <ContainerButton className='p-1'> <img src={EndTime} /></ContainerButton>
                            <Input1
                                className='bg-transparent border-0'
                                disabled={true}
                                placeholder={new Date(singleEventData?.end_time).toLocaleString()}
                            />
                        </InputContainer>
                    </div>
                    <div className='col-md-3'>
                        <Label>Betting Close Time</Label>
                        <InputContainer className='gap-2'>
                            <ContainerButton className='p-1'> <img src={CloseTime} /></ContainerButton>
                            <Input1
                                className='bg-transparent border-0'
                                disabled={true}
                                placeholder={new Date(singleEventData?.close_time).toLocaleString()}
                            />
                        </InputContainer>
                    </div>
                    <div className='col-md-3'>
                        <Label>Event Resolution Time</Label>
                        <InputContainer className='gap-2'>
                            <ContainerButton className='p-1'> <img src={ResolutionTime} /></ContainerButton>
                            <Input1
                                className='bg-transparent border-0'
                                disabled={true}
                                placeholder={new Date(singleEventData?.resolution_time).toLocaleString()}
                            />
                        </InputContainer>
                    </div>
                </div>
                <ViewOption className='mt-5'>
                    <DataTable
                        columns={columns}
                        data={singleEventData?.options}
                        customStyles={customStyles}
                    />
                </ViewOption>
                <div className='mt-4'>
                    <Heading className='mb-2'>Description</Heading>
                    <Subhead>{singleEventData?.description}</Subhead>
                </div>
            </Card2>

            <Modal show={updateOds}>
                <div className='d-flex justify-content-between'>
                    <div className='modal-head'>
                        <p className="heading mb-0">Update Odd</p>
                        <p className='sub-heading'>manually update the odd for this bet</p>
                    </div>
                    <p onClick={handleClose} className='cross-button'>x</p>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='inputcontainer'>
                        <label htmlFor='updateOdds'>Odd</label>
                        <input
                            placeholder='Enter Odd'
                            value={formik.values.updateOdds}
                            onChange={formik.handleChange}
                            name='updateOdds'
                            id='updateOdds'
                        />
                    </div>
                    <div className='inputcontainer'>
                        <label htmlFor='reason'>Reason</label>
                        <input
                            placeholder='Enter Reason for change'
                            value={formik.values.reason}
                            onChange={formik.handleChange}
                            name='reason'
                            id='reason'
                        />
                    </div>
                    <button type='submit' className='button'>Change Odd</button>
                </form>
            </Modal>
        </div>
    );
}